import "./App.css";
import { New_BgcForm } from "./components/New_BgcForm";

function App() {
  return (
    <div className="App">
      <New_BgcForm />
    </div>
  );
}

export default App;
